import React, { useState } from 'react';

import { ArrowButton } from './../index';
import OurVanilla from './../../assets/vanilladream_bg_desktop_005.jpg';
import OurFarm from './../../assets/activity_vanilla_01_header@2x.jpg';
import OurSaSApp from './../../assets/vanilladream_bg_desktop_003.jpg';
import AboutUs from './../../assets/handbook_vanilla_03_header@2x.jpg';
import ContactUs from './../../assets/vanilladream_bg_desktop_002.jpg';
import WorkWithUs from './../../assets/vanilladream_bg_desktop_006.jpg';

import './top-header.scss';


const TopHeader = () => {
    const [imagemenu, setImageMenu] = useState(OurVanilla);
    let el = document.getElementsByClassName("home-container");

    const openMenu = (e) => {
        document.getElementById("navigation").style.width = "100%";
        console.log(document.getElementsByClassName("home-container"));
        for (let index = 0; index < el.length; index++) {
            el[index].style.display = 'none';
        }
    }

    const closeMenu = (e) => {
        document.getElementById("navigation").style.width = "0%";
        for (let index = 0; index < el.length; index++) {
            el[index].style.display = 'block';
        }
    }
    return (
        <div className="top-wrapper">
            <button className="join-us">JOIN US</button>
            <span>MENU</span>
            <button className="menu-button" id="menu" onClick={openMenu}>
                <span className="burger-icon"></span>
            </button>

            <div id="navigation" className="overlay">
                <div className="bg-menu" style={{ backgroundImage: `url(${imagemenu})` }}></div>
                <button className="closebtn" onClick={closeMenu}><span>CLOSE</span> &times;</button>
                <div className="overlay-content">
                    <a href="/Our-Vanilla" onClick={() => setImageMenu(OurVanilla)}>Our Vanilla <ArrowButton /></a>
                    <a href="/Our-Farm" onClick={() => setImageMenu(OurFarm)}>Our Farm <ArrowButton /></a>
                    <a href="/Our-SaaS-App" onClick={() => setImageMenu(OurSaSApp)}> Our SaS App <ArrowButton /></a>
                    <a href="/About-Us" onClick={() => setImageMenu(AboutUs)}>About Us  <ArrowButton /></a>
                    <a href="/Contact-Us" onClick={() => setImageMenu(ContactUs)}>Contact Us  <ArrowButton /></a>
                    <a href="/Work-With-Us" onClick={() => setImageMenu(WorkWithUs)}>Work with Us  <ArrowButton /></a>
                </div>
            </div>
        </div>
    )
}

export default TopHeader;
