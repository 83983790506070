import React from 'react';
import { Switch, Route } from "react-router-dom";
import { Home, OurVanilla, OurFarm, AboutUs, ContactUs, OurSASApp, WorkWithUs } from './pages/index';

import './App.scss';
import './styles/style.scss';

import { Content } from './pages';

function App() {
  return (
    <div className="App">
      <Content />
      <Switch>
        <Route exact path={"/"} render={props => <Home {...props} />} />
        <Route exact path={"/Our-Vanilla"} render={props => <OurVanilla {...props} />} />
        <Route exact path={"/Our-Farm"} render={props => <OurFarm {...props} />} />
        <Route exact path={"/About-Us"} render={props => <AboutUs {...props} />} />
        <Route exact path={"/Contact-Us"} render={props => <ContactUs {...props} />} />
        <Route exact path={"/Our-SaaS-App"} render={props => <OurSASApp {...props} />} />
        <Route exact path={"/Work-With-Us"} render={props => <WorkWithUs {...props} />} />
      </Switch>
    </div>
  );
}

export default App;