import React from 'react';
import { ReactComponent as Arrow } from './../../assets/svg/iconmonstr-arrow-down-thin.svg';
import './arrowdown-button.scss';

const ArrowDownButton = () => {
    return (
        <button className="arrowdown-style "><Arrow /></button>
    );
}

export default ArrowDownButton;