import React from 'react';

import logo from './../../assets/logo-removebg-preview.png';
import facebook from './../../assets/svg/iconmonstr-facebook-4.svg';
import instagram from './../../assets/svg/iconmonstr-instagram-11.svg';
import twitter from './../../assets/svg/iconmonstr-twitter-1.svg';
import './side-header.scss';

const SideHeader = () => {
    return (
            <div className="sidenav">
                <a href="/">
                    <img src={logo} alt="Vanilla Dream" height="110px" width="120px" className="icon" />
                </a>
                <div className="logo-container">
                    <a href="https://www.facebook.com/vanilladreamph" target="_blank" rel="noopener noreferrer">
                        <img src={facebook} alt="facebook" className="icon logo" />
                    </a>
                    <a href="https://instagram.com/vanilladreamph" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} alt="instagram" className="icon logo" />
                    </a>
                    <a href="https://twitter.com/vanilladreamph" target="_blank" rel="noopener noreferrer">
                        <img src={twitter} alt="twitter" className="icon logo" />
                    </a>
                </div>
            </div>
    );
}

export default SideHeader;