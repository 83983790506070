import React from "react";
import { ArrowDownButton, Footer, ExploreSection } from './../../components/index';
import homeVideo from './../../assets/Vanilla_ani1280x720.mp4';
import './our-vanilla.scss';

function OurVanilla() {
    return (
        <div className="ourvanilla-container bg-container">
            <section id="ourvanilla-section-one" className="row row-reverse">
                <div className="img-column">
                    <div className="ourvanilla-image section-wrapper right">
                        <a href="#ourvanilla-section-two"><div className="section-left-button arrowdown-button"><ArrowDownButton /></div></a>
                    </div>
                </div>
                <div className="text-column">
                    <div className="landing-section-text">
                        <div className="title header-font">The world's finest vanilla from the Philippines.</div>
                        <div className="section-subtext">
                            Here at Vanilla Dream, we thrive to craft some of the worlds finest vanilla products.<br/><br/>
                            Through smart farming technologies and traditional artisan curing methods, we have the ability to ensure consistent high quality vanilla products.
                        </div>
                    </div>
                </div>
            </section>
            <section id="ourvanilla-section-two" className="row">
                <div className="img-column">
                    <div className="ourvanilla2-image section-wrapper right left">
                    </div>
                </div>
                <div className="text-column">
                    <div className="section-left-text">
                        <div className="section-text header-font">All our <i className="styled-text">premiums</i> beans are <i className="styled-text">organically grown</i>, carefully hand-selected and cured <i className="styled-text">in house</i>.</div>
                        <div className="section-subtext">For inquiries, volume purchases and long term partnership please inquire below.</div>
                    </div>
                </div>
            </section>
            <ExploreSection />
            <section className="row" id="ourvanilla-section-four">
                <div className="image-before-footer" style={{opacity: `100`}}>
                    <video autoPlay='autoplay' loop="loop" className="carousel-slide" muted>
                        <source src={homeVideo} type="video/mp4" />
                    </video>
                </div>
            </section>
            <Footer component="ourVanilla" />
        </div>
    );
}

export default OurVanilla;