import React from "react";
import { ArrowDownButton, Footer, ExploreSection } from './../../components/index';
import './our-farm.scss';

function OurFarm() {
    return (
        <div className="ourfarm-container bg-container">
            <section id="ourfarm-section-one" className="row row-reverse">
                <div className="img-column">
                    <div className="ourfarm-image section-wrapper right">
                        <a href="#ourfarm-section-two"><div className="section-left-button arrowdown-button"><ArrowDownButton /></div></a>
                    </div>
                </div>
                <div className="text-column">
                    <div className="landing-section-text">
                        <div className="title header-font">Organically grown artisan vanilla from the Philippines.</div>
                        <div className="section-subtext">
                        Organic Farming – We are firm believers of organic farming. <br/><br/>
                        We do not use any pesticides and chemicals on our farms which can be harmful to our farmers, the soil and decrease overall plant health.
                        </div>
                    </div>
                </div>
            </section>
            <section id="ourfarm-section-two" className="row">
                <div className="img-column">
                    <div className="ourvanilla2-image section-wrapper right left">
                    </div>
                </div>
                <div className="text-column">
                    <div className="section-left-text" style={{paddingTop: `25px`}}>
                        {/* <div className="section-text header-font">All our <i className="styled-text">premiums</i> beans are <i className="styled-text">organically grown</i>, carefully hand-selected and cured <i className="styled-text">in house</i>.</div> */}
                        <div className="section-subtext">
                            For mulch we only use organic coco husk which is a natural and renewable resource. It helps limit pests, weeds and other diseases. <br/><br/>
                            With its unique water holding capacity it reduces water frequencies and thereby reduces labor cost. 
                        </div>
                        <div className="section-text header-font">As tutot <i className="styled-text">trees</i> for our <i className="styled-text">vanilla vines </i>we use Gliricidia Sepium.</div> 
                        <div className="section-subtext">
                        The leaves are rich in nitrogen, an important soil nutrient. The discarded leaves works as a natural organic fertilizer and has anti-fungus.
                        </div>
                    </div>
                </div>
            </section>
            <ExploreSection />
            <section className="row" id="ourvanilla-section-four">
                <div className="image-before-footer homefooter-image"></div>
            </section>
            <Footer  component="ourFarm"/>
        </div>
    );
}

export default OurFarm;