import React from 'react';

import './learnmore-button.scss';

const LearnMoreButton = () => {
    return (
        // <div className="learnmore-button-center">
             <button className="learnmore-style">LEARN MORE</button>
        // </div>
    );
}

export default LearnMoreButton;