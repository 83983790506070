import React, { useEffect } from "react";
import { ExploreSection, Footer, ArrowDownButton } from './../../components/index';
import mobile1 from './../../assets/phone_03@2x.png';
import mobile2 from './../../assets/phone_02@2x.png';
import homeVideo from './../../assets/Vanilla_ani1280x720.mp4';
import { ReactComponent as Previous } from './../../assets/svg/iconmonstr-arrow-63.svg';
import { ReactComponent as Next } from './../../assets/svg/iconmonstr-arrow-64.svg';
import LocomotiveScroll from "locomotive-scroll";

import './our-saas-app.scss';

function OurSASApp() {
    useEffect(() => {
        const scroll = new LocomotiveScroll({
            el: document.querySelector('.App'),
            smooth: true
        });

        scroll.on('scroll', (args) => {
            let el = document.getElementById('mobile-overview');
            if (args.currentElements['overview'] === undefined) {
                if (!el.classList.contains('mobile-wrap')) {
                    el.className += " mobile-wrap ";
                }
                if (args.scroll.y > 0) {
                    if (el.classList.contains('mobile-wrap')) {
                        el.classList.remove('mobile-wrap');
                    }
                }
            } else {
                if (el.classList.contains('mobile-wrap')) {
                    el.classList.remove('mobile-wrap');
                }
                if (!el.classList.contains('no-rotate')) {
                    el.className += " no-rotate ";
                }
            }
        }, []);

        showSlide();

        return () => {
            scroll.destroy();
            console.log("destory:: ", scroll);
        }
    });

    const next = (n) => {
        //TODO: NEXT SLIDE
    }

    const previous = (n) => {
        //TODO: PREV SLIDE
    }

    const showSlide = (index) => {
        console.log(index);
        //TODO: SHOW PRESENT SLIDE
    }

    return (
        <div>
            <div data-scroll-container className="ourSaaSApp-container bg-container" >
                <section data-scroll-section>
                    <div className="text-column">
                        <div className="saas-header">
                            <div className="title header-font">
                                Discover a new way to grow quality vanilla with our SaaS App.
                        </div>
                        </div>
                    </div>
                </section>
                <section data-scroll-section id="stick" className="mobile-section">
                    <div className="mobile-handbook">
                        <img src={mobile2} alt="handbook" className="handbook" />
                        <div className="section-wrapper">
                            <a href="#mobile-overview-section"><div className="section-left-button arrowdown-button saas-down-button"><ArrowDownButton /></div></a>
                        </div>
                    </div>

                    <div data-scroll data-scroll-id="overview" data-scroll-speed="1" data-scroll-sticky data-scroll-target="#stick" className="mobile-overview">
                        <img src={mobile1} alt="overview" className="overview mobile-wrap" id="mobile-overview" />
                    </div>
                    <div className="img-colum mobile-img-column" id="mobile-overview-section">
                        <div className="mobile-circle"></div>
                    </div>
                    <div className="text-column mobile-desc">
                        <div className="section-right-text">
                            <div className="section-subtext">Our SaaS App - Just about every aspect of farming can benefit from technological advancement.</div><br /><br />
                            <div className="section-subtext">Especially for a delicate crop such as Vanilla! From planting to harvesting – crop health is key! Our smart farms are all equipped with sophisticated IoT-sensors that collects data such as light levels, wind, humidity, air quality, rainfall and temperature..</div>
                        </div>
                    </div>
                    <div className="mobile-navs">
                        <button className="prev" onClick={() => next(1)}><Previous className="svg-slider" /></button>
                        <button className="next" onClick={() => previous(-1)}><Next className="svg-slider" /></button>
                    </div>
                </section>
                <ExploreSection />
                <section className="row" id="ourvanilla-section-four" data-scroll-section>
                    <div className="image-before-footer" style={{ opacity: `100` }}>
                        <video autoPlay='autoplay' loop="loop" className="carousel-slide" muted>
                            <source src={homeVideo} type="video/mp4" />
                        </video>
                    </div>
                </section>
                <section data-scroll-section>
                    <Footer component="ourVanilla" />
                </section>
            </div>
        </div>
    );
}

export default OurSASApp;