import React, { useEffect } from 'react';
import { ArrowDownButton, Footer, ExploreSection } from './../../components/index';
// import user from './../../assets/user.PNG';
// import { ReactComponent as Previous } from './../../assets/svg/iconmonstr-arrow-63.svg';
// import { ReactComponent as Next } from './../../assets/svg/iconmonstr-arrow-64.svg';
import './about-us.scss';

function AboutUs() {
    /***
     * TODO: SLIDER
     */
    // let slideIndex = 1;
    // let minDisplay = 3;

    useEffect(() => {
        showSlide();
    });

    // const next = (n) => {
    //     // showSlide(slideIndex =+n);
    // }

    // const previous = (n) => {
    //     // showSlide(slideIndex = n);
    // }

    const showSlide = (index) => {
        console.log(index);
        // let slides = document.getElementsByClassName("user-slide");
        // if (index === 3) {
            
        // }
        // if (index > slides.length) {
        //     slideIndex = 1;
        // }
        // if (index > 3) {
        //     console.log()
        // }
    }

    return (
        <div className="aboutus-container bg-container">
            <section id="aboutus-section-one" className="row row-reverse">
                <div className="img-column">
                    <div className="aboutus-image section-wrapper right">
                        <a href="#aboutus-section-two"><div className="section-left-button arrowdown-button"><ArrowDownButton /></div></a>
                    </div>
                </div>
                <div className="text-column">
                    <div className="landing-section-text">
                        <div className="title header-font">We are a young team with enormous love for vanilla.</div>
                        <div className="section-subtext">
                        We all come from different backgrounds and together we believe we have the skillset to put Philippines on the map as one of the top Vanilla producing countries in the world.
                        </div>
                    </div>
                </div>
            </section>
            <section id="aboutus-section-two" className="row">
                <div className="img-column">
                    <div className="ourvanilla2-image section-wrapper right left">
                    </div>
                </div>
                <div className="text-column">
                    <div className="section-left-text">
                        <div className="section-text header-font">We strive to <i className="styled-text">run</i> this company with <i className="styled-text">compassion, kindness</i> and empathy.</div>
                        <div className="section-subtext">
                            To be environmentally sustainable and to improve the lives of filipino farmers and their families. <br/><br/>
                            Vanilla Dream Eco-Farms Inc Philippines
                        </div>
                    </div>
                </div>
            </section>
            {/* <section id="aboutus-section-three">
                <h1 className="founder-header header-font">Our co-founders formed the company in 2019</h1>
                <div className="column-container">
                    <div className="column-item">
                        <img src={user} alt="user" className="founder-img"/>
                        <div className="section-subtext" style={{width: `300px`}}>
                            <h2>Dennis Milkkensen</h2>
                            Co-founder Vanilla Dream and International Business Manager
                        </div>
                    </div>
                    <div className="column-item">
                        <img src={user} alt="user" className="founder-img"/>
                        <div className="section-subtext" style={{width: `300px`}}>
                            <h2>Dennis Milkkensen</h2>
                            Co-founder Vanilla Dream and International Business Manager
                        </div>
                    </div>
                    <div className="column-item">
                        <img src={user} alt="user" className="founder-img"/>
                        <div className="section-subtext" style={{width: `300px`}}>
                            <h2>Dennis Milkkensen</h2>
                            Co-founder Vanilla Dream and International Business Manager
                        </div>
                    </div>
                    <div style={{marginLeft: `auto`}}>
                        <button className="prev" onClick={() => next(1)}><Previous className="svg-slider" /></button>
                        <button className="next" onClick={() => previous(-1)}><Next className="svg-slider" /></button>
                 </div>
                </div> */}
                {/* <div className="column-container user-slide">
                    <div className="column-item">
                        <img src={user} alt="user" className="founder-img"/>
                        <div className="section-subtext" style={{width: '300px'}}>
                            <h2>Dennis Milkkensen</h2>
                            Co-founder Vanilla Dream and International Business Manager
                        </div>
                    </div>
                    <div className="column-item">
                        <img src={user} alt="user" className="founder-img"/>
                        <div className="section-subtext" style={{width: '300px'}}>
                            <h2>Dennis Milkkensen</h2>
                            Co-founder Vanilla Dream and International Business Manager
                        </div>
                    </div>
                    <div className="column-item">
                        <img src={user} alt="user" className="founder-img"/>
                        <div className="section-subtext" style={{width: '300px'}}>
                            <h2>Dennis Milkkensen</h2>
                            Co-founder Vanilla Dream and International Business Manager
                        </div>
                    </div>
                </div> */}
            {/* </section> */}
            <ExploreSection />
            <section className="row" id="ourvanilla-section-four">
                <div className="image-before-footer homefooter-image"></div>
            </section>
            <Footer component="aboutUs" />
        </div>
    );
}

export default AboutUs;