import React from 'react';
import { ReactComponent as Arrow } from './../../assets/svg/iconmonstr-arrow-right-thin.svg';

import './arrow-button.scss';

const ArrowButton = () => {
    return (
        <button className="arrow">
            <Arrow />
        </button>
    )
}

export default ArrowButton;