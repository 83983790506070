import React from 'react';
import './contactus-form.scss';


const ContactUsForm = () => {

    const onInputFocus = (e) => {
        document.querySelectorAll('.input__field').forEach(element => {
            if (element.value.trim() !== '') {
                element.parentNode.classList.add('input--filled');
            } else {
                element.parentNode.classList.remove('input--filled');
            }
        });
    }

    return (
        <div className="column-container">
            <div className="contacform-column">
                <span className="input input--nao">
                    <input className="input__field input__field--nao" type="text" id="input-1" onChange={onInputFocus} />
                    <label className="input__label input__label--nao" htmlFor="input-1">
                        <span className="input__label-content input__label-content--nao">Name*</span>
                    </label>
                    <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60" preserveAspectRatio="none">
                        <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0" />
                    </svg>
                </span>
            </div>
            <div className="contacform-column">
                <span className="input input--nao">
                    <input className="input__field input__field--nao" type="text" id="input-2" onChange={onInputFocus} />
                    <label className="input__label input__label--nao" htmlFor="input-2">
                        <span className="input__label-content input__label-content--nao">Phone number*</span>
                    </label>
                    <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60" preserveAspectRatio="none">
                        <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0" />
                    </svg>
                </span>
            </div>
            <div className="contacform-column">
                <span className="input input--nao">
                    <input className="input__field input__field--nao" type="text" id="input-3" onChange={onInputFocus} />
                    <label className="input__label input__label--nao" htmlFor="input-3">
                        <span className="input__label-content input__label-content--nao">Email*</span>
                    </label>
                    <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60" preserveAspectRatio="none">
                        <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0" />
                    </svg>
                </span>
            </div>
            <div className="contacform-column" style={{ margin: `-15px 0` }}>
                <span className="input input--nao">
                    <textarea className="input__field input__field--nao" type="text" id="input-4" onChange={onInputFocus} />
                    <label className="input__label input__label--nao" htmlFor="input-4">
                        <span className="input__label-content input__label-content--nao" style={{ lineHeight: `60pt` }}>Message</span>
                    </label>
                    <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60" preserveAspectRatio="none">
                        <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0" />
                    </svg>
                </span>
            </div>
            <div className="contacform-column">
                <button className="sendMessage-button">SEND MESSAGE</button>
            </div>
        </div>
    )
}

export default ContactUsForm;