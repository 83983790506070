import React from 'react';
import { ArrowDownButton, Footer, ContactUsForm } from './../../components/index';
import './contact-us.scss';

function ContactUs() {
    return (
        <div className="contactus-container bg-container">
            <section id="aboutus-section-one" className="row row-reverse">
                <div className="img-column">
                    <div className="contactus-image section-wrapper right">
                        <a href="#contactus-section-two"><div className="section-left-button arrowdown-button"><ArrowDownButton /></div></a>
                    </div>
                </div>
                <div className="text-column">
                    <div className="landing-section-text">
                        <div className="title header-font">Smart farming the future of the vanilla Industry.</div>
                        <div className="section-subtext">
                            Feel free to talk with us and send us a message about you need to know, or say Hello :)
                        </div>
                    </div>
                </div>
            </section>
            <section id="contactus-section-two" className="row">
                <ContactUsForm/>
            </section>
            <section className="row">
                <div className="image-before-footer contactUs-footer"></div>
            </section>
            <Footer component="contactUs" />
        </div>
    );
}

export default ContactUs;