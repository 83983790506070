import React from 'react';

import { GoToButton } from './../index'

import './explore-section.scss'; 

const ExploreSection = () => {
    return (
        <section className="row" id="exploreSection" data-scroll-section>
            <div className="explore-section">
                <h1 className="header-font">Explore our opportunities</h1>
                <div>
                    <ol className="explore-opportunities">
                        <div className="line-separator" style={{ width: `100%` }}></div>
                        <li><div className="explore-text header-font">Contract Farming Program <GoToButton /></div></li>
                        <div className="line-separator header-font" style={{ width: `100%` }}></div>
                        <li><div className="explore-text header-font">Dedicated Program <GoToButton /></div></li>
                        <div className="line-separator" style={{ width: `100%` }}></div>
                        <li><div className="explore-text header-font">Careers <GoToButton /></div></li>
                    </ol>
                </div>
            </div>
        </section>
    );
}

export default ExploreSection;