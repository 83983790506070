import React from "react";
import { ArrowDownButton, Footer } from './../../components/index';
import './work-with-us.scss';

function WorkWithUs() {
    return (
        <div className="workwithus-container bg-container">
            <section id="workwithus-section-one" className="row row-reverse">
                <div className="img-column">
                    <div className="ourvanilla2-image section-wrapper right">
                        <a href="#workwithus-section-two"><div className="section-left-button arrowdown-button"><ArrowDownButton /></div></a>
                    </div>
                </div>
                <div className="text-column">
                    <div className="landing-section-text">
                        <div className="title header-font">Explore our Opportunities and work with us</div>
                        <div className="section-subtext">
                            Here at Vanilla Dream we thrive to craft some of the worlds finest vanilla products. <br /><br />
                            Through smart farming technologies and traditional artisan curing methods we have the ability to ensure consistent high quality vanilla products.
                        </div>
                    </div>
                </div>
            </section>
            <section id="workwithus-section-two">
                <div className="boder-div">
                    <div className="w-hr"></div>
                    <div className="ol-div ">
                        <div className="w-number" id="one"><p>01.</p></div>
                        <div className="w-context">
                            <div className="w-title header-font">Vanilla Dream <br /> Contract Farming</div>
                            <p className="section-subtext w-subtext">
                                Contract farming refers to an arrangement in which buyers and
                                farmers agree to the terms of their business relationship before
                                agricultural production begins.
                            </p>
                            <p className="section-subtext w-subtext">
                                The agreement can include how the price will be set, access to
                                credit, the quantity and quality of production, relevant certification
                                schemes, and access
                                to markets.
                            </p>
                            <p className="section-subtext w-subtext">
                                Contract farming has grown in prominence in recent years to secure
                                production for global value chains. But, in many cases, it has come
                                with an exacerbation of historical power asymmetries between
                                buyers and farmers.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="boder-div">
                    <div className="w-hr"></div>
                    <div className="ol-div ">
                        <div className="w-number" id="two"><p>02.</p></div>
                        <div className="w-context">
                            <div className="w-title header-font">Get your own <br /> Dedicated Farm!</div>
                            <p className="section-subtext w-subtext">
                                The farm is controlled through Our SaaS App - Just about every
                                aspect of farming can benefit from technological advancement.
                                Especially for a delicate crop such as Vanilla! From planting to
                                harvesting –ccrop health is key!
                            </p>
                            <p className="section-subtext w-subtext">
                                Our smart farms are all equipped with sophisticated IoT-sensors
                                that collects data such as light levels, wind, humidity, air quality,
                                rainfall and temperature. Combine this with hourly, daily and
                                historically satellite imagery, such as NDVI, EVI vegetation indices
                                and extensive weather forecasts from third party APIs, you have
                                what we call precision farming!
                            </p>
                            <p className="section-subtext w-subtext">
                                Sustainable Smart Farming - The future of agriculture!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="boder-div">
                    <div className="w-hr"></div>
                    <div className="ol-div ">
                        <div className="w-number" id="three"><p>03.</p></div>
                        <div className="w-context">
                            <div className="w-title header-font">Our careers. <br /> Join our Team!</div>
                            <p className="section-subtext w-subtext">
                                We’re on a mission to make working life simpler, more pleasant
                                and more productive – for everyone
                            </p>
                            <p className="section-subtext w-subtext">
                                We are creative, innovative and fearless about trying new things.
                                We embrace different ideas and are visionaries.
                            </p>
                            <p className="section-subtext w-subtext">
                                Our teammates & ourselves We are a company of problem-solvers
                                and self-starters. We move fast, remain flexible and get results.
                            </p>
                            <p className="section-subtext w-subtext">
                                Our Customers We are team players, and collaborate to get things
                                done. We are humble. We take care of each other and share our
                                lives at work.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="row" id="workwithus-section-three">
                <div className="image-before-footer ourvanilla2-image" style={{backgroundPosition: `center`}}></div>
            </section>
            <Footer component="workWithUs" />
        </div>
    );
}

export default WorkWithUs;