import React from 'react';
import { ReactComponent as Arrow } from './../../assets/svg/iconmonstr-arrow-right-thin.svg';

import './goto-button.scss';

const GoToButton = () => {
    return (
        <button className="goto-arrow">
            <Arrow />
        </button>
    )
}

export default GoToButton;