import React from "react";
import SideHeader from "../../components/side-header/side-header";
import TopHeader from "../../components/top-header/top-header";

import "./content.scss";

function Content() {
    return (
        <div className="content">
            <TopHeader/>
            <SideHeader />
            
        </div>
    )
}

export default Content;