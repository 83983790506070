import React, { useEffect } from 'react';
import slide1 from './../../assets/Vanilla_ani1280x720.mp4';
import slide2 from './../../assets/vanilladream_bg_desktop_005.jpg';
import slide3 from './../../assets/vanilladream_bg_desktop_002.jpg';
import { GoToButton, LearnMoreButton, Footer, ExploreSection } from './../../components/index';
import { ReactComponent as Previous } from './../../assets/svg/iconmonstr-arrow-63.svg';
import { ReactComponent as Next } from './../../assets/svg/iconmonstr-arrow-64.svg';

import './home.scss';

function Home() {
    let slideIndex = 1;
    useEffect(() => {
        showSlide(slideIndex);
    });

    const toSlide = (index) => {
        showSlide(slideIndex += index);
    }

    const showSlide = (index) => {

        let totalSlides = 3;
        let slides = document.getElementsByClassName("home-slide");
        if (index > totalSlides) { slideIndex = 1; }
        if (index < 1) { slideIndex = totalSlides; }
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = 'none';
        }
        slides[slideIndex - 1].style.display = 'block';
    }

    return (
        <div className="home-container bg-container">
            <div id="home-section-one">
                <div className="slide-wrapper">
                    <div className="home-slide">
                        <video autoPlay='autoplay' loop="loop" className="carousel-slide" muted>
                            <source src={slide1} type="video/mp4" />
                        </video>
                        <div className="slide-text header-font">The world's finest vanilla from the Philippines.</div>
                        <div className="slide-goto">DISCOVER <a href="/Our-Vanilla"><GoToButton /></a></div>
                    </div>
                    <div className="home-slide">
                        <img src={slide2} alt="slide 2" className="carousel-slide img-slide" />
                        <div className="slide-text header-font">Organically grown artisan vanilla from the Philippines.</div>
                        <div className="slide-goto">OUR FARMS <a href="/Our-Farm"><GoToButton /></a></div>
                    </div>
                    <div className="home-slide">
                        <img src={slide3} alt="slide 3" className="carousel-slide img-slide" />
                        <div className="slide-text header-font">Smart farming the future of the vanilla Industry.</div>
                        <div className="slide-goto">CONTACT US <a href="/Contact-Us"><GoToButton /></a></div>
                    </div>
                    <div className="slide-buttons-wrapper ">
                        <button className="prev" onClick={() => toSlide(1)}><Previous className="svg-slider" /></button>
                        <button className="next" onClick={() => toSlide(-1)}><Next className="svg-slider" /></button>
                    </div>
                </div>
            </div>
            <section id="home-section-two" className="row row-reverse">
                <div className="img-column">
                    <div className="ourvanilla-image section-wrapper right">
                        <a href="/Our-Vanilla"><div className="section-left-button"><LearnMoreButton /></div></a>
                    </div>
                </div>
                <div className="text-column">
                    <div className="section-left-text">
                        <div className="section-title">OUR VANILLA</div>
                        <div className="line-separator"></div>
                        <div className="section-text header-font">We thrive to <i className="styled-text">craft</i> some of the <i className="styled-text">worlds finest vanilla</i> products.</div>
                        <div className="section-subtext">All our premiums beans are organically grown, carefully hand-selected and cured in house.</div>
                    </div>
                </div>
            </section>
            <section id="home-section-three" className="row">
                <div className="img-column">
                    <div className="ourfarm-image section-wrapper right left">
                    <a href="/Our-Farm"><div className="section-right-button"><LearnMoreButton /></div></a>
                    </div>
                </div>
                <div className="text-column">
                    <div className="section-left-text">
                        <div className="section-title">OUR FARMS</div>
                        <div className="line-separator"></div>
                        <div className="section-text header-font">Organic farming - <br /> We are firm <i className="styled-text">believer</i> of organic <i className="styled-text">farming</i>.</div>
                        <div className="section-subtext">We do not use any pesticides and chemicals on our farms which can be harmful to our farmers, the soil and decrease overall plant health.</div>
                    </div>
                </div>
            </section>
            <section id="home-section-four" className="row row-reverse">
                <div className="img-column">
                    <div className="aboutus-image section-wrapper right">
                        <a href="/About-Us"><div className="section-left-button"><LearnMoreButton /></div></a>
                    </div>
                </div>
                <div className="text-column">
                    <div className="section-left-text">
                        <div className="section-title">ABOUT US</div>
                        <div className="line-separator"></div>
                        <div className="section-text header-font">We strive to <i className="styled-text">run</i> this company with <i className="styled-text">compassion, kindness</i> and empathy.</div>
                        <div className="section-subtext">To be environmentally sustainable and to improve the lives of filipino farmers and their families.</div>
                    </div>
                </div>
            </section>
            <section id="section-two" className="row">
                <div className="img-column ourSaSApp-column">
                    <div className="ourSaSApp-image section-wrapper right left">
                    </div>
                </div>
                <div className="text-column">
                    <div className="section-left-text ourSaSApp-text">
                        <div className="section-title">OUR SAAS APP</div>
                        <div className="line-separator"></div>
                        <div className="section-text header-font">Just about <i className="styled-text">every aspect</i> of farming can benefit from <i className="styled-text">technological</i> advancement.</div>
                        <a href="/Our-SaaS-App" className="text-decoration"><div className="section-title">DISCOVER<GoToButton /></div> </a>
                    </div>
                </div>
            </section>
            <ExploreSection />
            <section className="row">
                <div className="image-before-footer homefooter-image"></div>
            </section>
            <Footer component="home" />
        </div>
    );
}

export default Home;