import React from 'react';

import './footer.scss';

const Footer = ({component}) => {
    return (
        <div className="footer-container">
            <div className="row footer-wrapper">
                <div className="text-column footer-text footer-column">
                    Let's make something great together.
                    <p className="footer-bigtext">Let’s talk!</p>
                   <a href="/Contact-Us" style={component === 'contactUs' ? {display: 'none'} : {display: 'block'}}><button className="getintouch">GET IN TOUCH</button></a>
                </div>
                <div className="text-column footer-column">
                    <a href="/Our-Vanilla" className="footer-menu"><p>OUR VANILLA</p></a>
                    <a href="/Our-Farm" className="footer-menu"><p>OUR FARM</p></a>
                    <a href="/Our-SaaS-App" className="footer-menu"><p>DISCOVER OUR SAS APP</p></a>
                    <a href="/About-Us" className="footer-menu"><p>LEARN ABOUT US</p></a>
                    <a href="/Work-With-Us" className="footer-menu"><p>WORK WITH  US</p></a>
                </div>
                <div className="text-column footer-column-three footer-column">
                    <p>Philippines</p>
                    <span className="footer-normal-text">Avenue Syris, Street Luxemburgo and Portugal, Manila.</span>
                    <br /><br />
                    <span className="footer-normal-text">Phone</span>
                    <p>(+45) 20 00 00 00</p>
                    <br />
                    <span className="footer-normal-text">Email</span>
                    <p>info@vanilladream.ph</p>
                </div>
                <div className="text-column footer-column end-footer"> We are Vanilla Dream, Eco Farms. <a href="/#" style={{ color: `#ffffff` }}>Privacy Policy</a></div>
                <div className="text-column footer-column end-footer" style={{ textAlign: `right` }}>© 2020 Vanilla Dream</div>
            </div>
        </div>
    )
}

export default Footer;